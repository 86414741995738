// assets
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PolicyIcon from '@mui/icons-material/Policy';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

import SearchIcon from '@mui/icons-material/Search';

const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  PaymentOutlinedIcon: PaymentOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  BookmarkOutlinedIcon: BookmarkOutlinedIcon,
  FavoriteBorderOutlinedIcon: FavoriteBorderOutlinedIcon,
  SendOutlinedIcon: SendOutlinedIcon,
  AddReactionIcon: AddReactionIcon,
  CampaignOutlinedIcon: CampaignOutlinedIcon,
  PowerSettingsNewOutlinedIcon: PowerSettingsNewOutlinedIcon,
  SettingsTwoToneIcon: SettingsTwoToneIcon,
  LockResetIcon: LockResetIcon,
  PersonRemoveIcon: PersonRemoveIcon,
  AssignmentIcon: AssignmentIcon,
  PolicyIcon: PolicyIcon,
  SearchIcon: SearchIcon,
  AdminPanelSettingsIcon: AdminPanelSettingsIcon,
  PermIdentityIcon: PermIdentityIcon
  
};

// eslint-disable-next-line 
export default {
  items: [
    {
      id: 'navigation',
      title: 'You',
      caption: '',
      type: 'group',
      icon: icons['NavigationOutlinedIcon'],
      children: [
        // {
        //   id: 'dashboard',
        //   title: 'Dashboard',
        //   type: 'item',
        //   icon: icons['HomeOutlinedIcon'],
        //   url: '/dashboard/default'
        // },
        {
          id: 'search-trainers',
          title: 'Search',
          type: 'item',
          url: '/search-trainers',
          icon: icons['SearchIcon']
        },
        {
          id: 'bookings',
          title: 'Bookings',
          type: 'item',
          url: '/bookings',
          icon: icons['BookmarkOutlinedIcon']
        },
        // {
        //   id: 'trainer-mode',
        //   title: 'Trainer Mode',
        //   type: 'item',
        //   icon: icons['FavoriteBorderOutlinedIcon'],
        //   url: '/training-mode'
        //   // children: [
        //   //   {
        //   //     id: 'about-me',
        //   //     title: 'About Me',
        //   //     type: 'item',
        //   //     url: '/about-me',
        //   //     icon: icons['PermIdentityIcon']
        //   //   },
        //   //   {
        //   //     id: 'background-check',
        //   //     title: 'Background Check',
        //   //     type: 'item',
        //   //     url: '/background-check',
        //   //     icon: icons['AdminPanelSettingsIcon']
        //   //   }
        //   // ]
        // },
        // {
        //   id: 'logout',
        //   title: 'Logout',
        //   type: 'item',
        //   url: '/login',
        //   icon: icons['PowerSettingsNewOutlinedIcon']
        // }
      ]
   },
    // {
    //   id: 'navigation',
    //   title: 'Support',
    //   caption: '',
    //   type: 'group',
    //   icon: icons['NavigationOutlinedIcon'],
    //   children: [
    //     {
    //       id: 'send-email',
    //       title: 'Send Email',
    //       type: 'item',
    //       icon: icons['SendOutlinedIcon'],
    //       url: '/send-email'
    //     },
    //     // {
    //     //   id: 'invite-friend',
    //     //   title: 'Invite a friend',
    //     //   type: 'item',
    //     //   url: '/invite-friend',
    //     //   icon: icons['AddReactionIcon']
    //     // },
    //     // {
    //     //   id: 'notifications',
    //     //   title: 'Notifications',
    //     //   type: 'item',
    //     //   url: '/notifications',
    //     //   icon: icons['CampaignOutlinedIcon']
    //     // },
        // {
        //   id: 'logout',
        //   title: 'Logout',
        //   type: 'item',
        //   url: '/login',
        //   icon: icons['PowerSettingsNewOutlinedIcon']
        // }
    //   ]
    // }
  ]
};
