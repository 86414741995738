import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';

import { useNavigate } from 'react-router-dom';

// import { useGet } from '../../../APIServices/getAPI.js';

// import * as Constants from '../../../Hooks/Constants.js';

// project import
import SearchSection from './SearchSection/index.js';
import ProfileSection from './ProfileSection/index.js';
import NotificationSection from './NotificationSection/index.js';
import { drawerWidth } from '../../../config.js';

// assets
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import logo from '../../../assets/images/logo.svg';

// ==============================|| HEADER ||============================== //

const Header = ({ drawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onChangeSearch = (event) => {
      let value = event.target.value;
      navigate('/search-trainers', {state: {search_txt: value}});
  }

  // const {error, response, isLoading, getData} = useGet("");

  // const onChangeSearch = (event) => {
  //   let value = event.target.value;
  //   if(value !== null) {
  //     let url = Constants.SEARCH_TRAINER_API + value;
  //     fetchData(url);
  //   }
  //     }
      
  //     async function fetchData(url) {
  //         await getData(url, false);
  //     }
    
  //     useEffect(()=> {
  //   if(response !== null) {
  //   response.then(val=> {
  //     let data = val["trainers"];
  //     console.log("data: ", data);
  //     navigate('/search-trainers', {state: {data: data, error: error, isLoading: isLoading}});
  //   })
  //   }
  //      }, [response, navigate, isLoading, error]);

  return (
    <>
    <Grid item>
              <IconButton
              edge="start"
              sx={{ mr: theme.spacing(1.25) }}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem', color: 'blue' }} />
            </IconButton>
          </Grid>
      <Box width={drawerWidth} sx={{paddingLeft: '10px'}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box mt={0.5}>
                <a href='/search-trainers'>
                <img src={logo} alt="Logo" />
                </a>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <SearchSection theme="light" onChangeSearch={(event) => onChangeSearch(event)}/>
      <Box sx={{ flexGrow: 2 }} />
      {/* <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;
